.userCard{
    display: flex;
    margin-top: 40px;
    // margin-left: 40px;
    // border: solid black 1px;
    background: rgba(0,0,0,1);
    color: white;
    width: 200px;
    max-height: 30px;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    font-family: 'M PLUS 1p', sans-serif;
    @media(max-width:500px) {
        width: 150px;
        margin-top: 5px;
        margin-left: 20px;
    }
    .userAvatar{
        border-radius: 50%;
        border: rgba(0,0,0,1) solid 1px;
        margin-top: -16px;
        margin-left: 10px;
        min-width: 60px;
        min-height: 60px;
        @media(max-width:500px) {
            display:none;
        }
    }
    .userInfo{
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        a{
            text-decoration: none;
            color: white;
            cursor:pointer;
            &:hover{
                color: black;
            }
        }

    }

}
