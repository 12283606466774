
.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
.drop{
    padding-top: 5px;
    text-align:center;
    align-items: center;
}
.rightContainer{
    right: 25px;
    width: 700px;
    top: 25px;
    position: absolute;
    -webkit-animation: fade-in-right 4s cubic-bezier(0.680, -0.550, 0.265, 1.550) 2s both;
    animation: fade-in-right 4s cubic-bezier(0.680, -0.550, 0.265, 1.550) 2s both;
    @media(max-width:1500px){
        top: 180px;
    }
    @media(max-width:600px){
        right: 0;
        width: 100%;
        top: 140px;
    }
    @media(max-width: 500px){
        
        top:80px;
    }
}
.buttonContainer{
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    .exportBtn{
        padding: 4px 10px;
        text-decoration: none;
        text-align: center;

        color: black;
        margin-top:20px;
        margin-left: 4.5%;
        font-family: 'M PLUS 1p', sans-serif;
        background-color: rgba(255, 255, 255, 0.5);
        border: solid 1px black;
        cursor:pointer;
        &:focus{
            outline: none;
            box-shadow: none;
        }
        &:hover{
            color:white;
            background-color: black;
        }

    }
    .disabled{
    
        background-color: rgba(0, 0, 0, 0.8);
        cursor: not-allowed;
        pointer-events: none;
        &:hover{
            color:black;
            background-color: rgba(0, 0, 0, 0.8);
        }
    }
}



.cardContainer{
    overflow-y: scroll;
    min-height: 80%;
    max-height: 80vh;
    margin-top: 5px;
    font-family: 'M PLUS 1p', sans-serif;  
    .bar{
        padding: 4px 20px;
        background: rgba(255,255,255,.7);
        border: 1px solid black;
        border-radius: 5px;
        display:flex;
        justify-content: space-between;
    } 
    .hidden{
        display:none;
    }
}
.itemCard{
    background: rgba(255,255,255,.7);
    margin: 5px 5px 5px auto;
    padding: 10px;
    width: 80%;
    border-radius: 20px;
    @media(max-width: 500px){
        margin: 0;
        padding: 10px 0;
        width:100%;
        border-radius: 0;
        border-bottom: 1px solid black;
    }
    .itemContainer{
        display:flex;  
        .topCardContainer{
            width: 80%;
            display: flex;
            justify-content: space-around;
            @media(max-width:500px){
                width: 100%;
            }

            .cardAvatar{
                width: 60px;
                height: 60px;
                border-radius: 20%;
                border: rgba(0,0,0,1) solid 1px;
            }

            .itemIcon{
                width: 60px;
                height: 60px;
            }
            .requestDescrip{
                display: flex;
                width: 70%;
                flex-direction: column;
                justify-content: space-between;
                .Crafter{  
                    img{
                        border-radius: 50%;
                        margin-left: 5px;
                        margin-bottom: -3px;
                        max-width: 20px;
                        max-height: 20px;
                    }
                }
            }
        }
    }
    .setContainer{
        display: flex  ;
        justify-content: space-between;
        margin: 10px auto;
        .leftSet{
            border-right: 1px black solid;
        }
        .leftSet, .rightSet{
            width: 50%;
            padding: 0 5px;
            .itemInfo{
                display: flex;
                align-items: center;
                padding-top: 5px;
                h6{
                    padding-left: 7px;
                }
            }
        }
        .rightSet{
            .itemInfo{    
                justify-content: flex-end;
                h6{
                    padding-right:7px;
                    text-align: right;
                }
            }
        }
    }
 

}




.requestOptions{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .completed{
        padding: 0 5px;
        border-radius: 5px;
        background-color: #3CB371;
        border: solid 1px black;
    }
    .inProgress{
        padding: 0 5px;
        border-radius: 5px;
        background-color: #FF7F50
        ;
        border: solid 1px black;
    }
    .submitted{
        padding: 0 5px;
        border-radius: 5px;
        background-color: rgb(112, 111, 111)
        ;
        border: solid 1px black;
    }
    .btn{
        border: solid 1px black;
        border-radius: 5px;
        text-align: center;
        background-color: white;
        margin-top: 3px;
        cursor:pointer;
        &:hover{
            background-color: black;
            color:white;
        }
        &.selected{
            background-color: #FF7F50;
        }
        @media(max-width: 500px){
            padding: 10px 10px;
        }
    }
    .completedBTN{
        display:none
    }
}







