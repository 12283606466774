@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p&display=swap');


  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    &:hover {
        background: rgb(184, 184, 184);
      }
  }







.container{
    overflow: hidden;
    position: relative;
    height: 100vh;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width:700px;
    margin: 300px 38px 0 35px;
    min-width: 600px;
    z-index: 99;
    -webkit-animation: fade-in-left 2s cubic-bezier(0.680, -0.550, 0.265, 1.550) 2s both;
    animation: fade-in-left 2s cubic-bezier(0.680, -0.550, 0.265, 1.550) 2s both;

    @media(max-width: 1500px){
        margin-top: 20px;
    }
    @media(max-width:700px){
        width:100%;
        min-width: 0;
        margin-left: 0px;
        margin-top: 0px;
    }
    .logo{
        width: 100%;
        height: auto;

    }
    .btn{
        text-decoration: none;
        text-align: center;
        padding: 4px 0;
        color: black;
        width: 100px;
        margin-top:20px;
        margin-left: 4.5%;
        font-family: 'M PLUS 1p', sans-serif;
        background-color: rgba(255, 255, 255, 0.5);
        border: solid 1px black;
        cursor:pointer;
        &:focus{
            outline: none;
            box-shadow: none;
        }
        &:hover{
            color:white;
            background-color: black;
        }
    }
}

h1{
	padding: 0;
    margin: 0;
    text-align: left;
    font-size: 5rem;
    color: black;
    font-family: 'M PLUS 1p', sans-serif;
    @media (max-width: 500px) {
        display:none;
    }
}








.leftShape{
    z-index: -1;
    position:absolute;
    top:0;
    left:-300px;
    width: 800px;
    height: 100%;
    transform:skew(-20deg);
    background: rgba(255, 255, 255, 0.5);
    @media(max-width: 500px) {
        width:1000px;
    }
}
.rightShape{
    z-index: -1;
    position:absolute;
    top:0;
    right: 100px;
    width: 3000px;
    height: 100%;
    transform:skew(-20deg);
    background: rgba(0, 0, 0, 0.5);
    animation: slide-in-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
    @media(max-width: 500px) {
        display:none;
    }
}


