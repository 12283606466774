#crystals {
    position:absolute;
    top:0;
    left:0;
    z-index: -1;
    width:480px;
    height:100%;
    background:transparent;
    .crystal_01, .crystal_02, .crystal_03 {
        position:absolute;
        width:100%;
        height:100%;
    
    }
    .crystal_01{
        background:url(http://img.finalfantasyxiv.com/lds/promo/pc/global/images/world/what/crystal_01.png)repeat-y;
    }
    .crystal_02 {
        background:url(http://img.finalfantasyxiv.com/lds/promo/pc/global/images/world/what/crystal_02.png)repeat-y;
    }
    .crystal_03 {
        left:50%;
        margin-left:-180px;
        background:url(http://img.finalfantasyxiv.com/lds/promo/pc/global/images/world/what/crystal_03.png)repeat-y;
    }
}